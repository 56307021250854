<template>
  <library-container>
    <div
      class="tw-flex tw-w-full tw-flex-col tw-py-16"
      :class="variants.V2Faqs === variant ? 'tw-items-start tw-justify-start' : 'tw-items-center tw-justify-center'"
    >
      <library-title v-if="vars.mainTitleText" :text="vars.mainTitleText" tag="h2"></library-title>
      <div
        class="tw-mt-12 tw-flex tw-w-full tw-flex-col tw-space-y-4 md:tw-flex-row md:tw-space-x-4 md:tw-space-y-0"
        :class="variant === variants.V3Blogs ? 'tw-mb-2' : 'tw-mb-24'"
      >
        <router-link
          v-for="(tab, index) in tabs"
          :key="`knowledge-hub-tab-${index}`"
          :style="
            activeTab === tab.name
              ? 'background-color: var(--camel); color: var(--white);'
              : 'background-color: var(--white); color: var(--raisin);'
          "
          :to="`/knowledge-hub/${tab.name}`"
          style="text-transform: uppercase; border-radius: 4px; text-align: center; font-weight: bold; cursor: pointer"
          class="tw-px-4 tw-py-6 md:tw-w-1/4"
        >
          {{ tab.title }}
        </router-link>
      </div>

      <library-title v-if="subTitle" :text="subTitle" tag="h3"></library-title>
      <div v-if="variant === variants.V2Faqs" class="tw-my-5 tw-w-full">
        <search-box
          ref="searchBox"
          v-model="faqSearchKeyword"
          :search-result-list="searchResultList"
          :search-method="onSearchFaqs"
          placeholder="Search"
        />
      </div>
      <div
        v-if="variant === variants.V1Guides"
        class="tw-mb-12 tw-mt-12 tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-y-4 md:tw-flex-row md:tw-gap-x-4 md:tw-gap-y-0"
      >
        <router-link
          v-for="(tab, index) in subTabs"
          :key="`knowledge-hub-sub-tab-${index}`"
          :style="
            activeSubTab === tab.name
              ? 'background-color: var(--camel); color: var(--white);'
              : 'background-color: var(--white); color: var(--raisin);'
          "
          :to="`${activeTab}` + `${tab.name ? `?type=${tab.name}` : ''}`"
          style="text-transform: uppercase; border-radius: 4px; text-align: center; font-weight: bold; cursor: pointer"
          class="tw-px-12 tw-py-4"
        >
          {{ tab.title }}
        </router-link>
      </div>
      <div v-if="variant === variants.V1Guides" class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-x-8 tw-gap-y-8">
        <theme3-components-guide-box
          v-for="(item, index) in guides"
          :key="`guide-${index}`"
          :item="item"
          class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-4 xl:tw-col-span-3"
          style-name="v1"
          :component="component"
        ></theme3-components-guide-box>
      </div>
      <div v-if="variant === variants.V2Faqs" class="tw-w-full">
        <library-accordion v-if="!isFaqResultsLoading" :elements="faqs" />
        <loader v-show="isFaqResultsLoading" />
      </div>
    </div>
  </library-container>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import LibraryAccordion from '~/components/library/content/Accordion.vue';
import SearchBox from '~/components/common/SearchBox.vue';
import { SearchEngine } from '~/core/engines/search.engine';
import { sleep } from '~/helpers';
import Loader from '~/components/common/Loader.vue';

export default defineNuxtComponent({
  name: 'Theme3KnowledgeHubPage',
  components: { Loader, SearchBox, LibraryAccordion },

  mixins: [ComponentMixin],

  data() {
    return {
      tabs: [
        {
          title: 'Sales Guides',
          name: 'sales-guides',
        },
        {
          title: 'Lettings Guides',
          name: 'lettings-guides',
        },
        {
          title: 'Faqs',
          name: 'faqs',
        },
        {
          title: 'Blogs',
          name: 'blogs',
        },
      ],

      variants: {
        V1Guides: 'V1Guides',
        V2Faqs: 'V2Faqs',
        V3Blogs: 'V3Blogs',
      },

      faqSearchKeyword: '',
      searchResultList: [],
      isFaqResultsLoading: false,
    };
  },

  created() {
    const route = useRoute();
    if (route.query.q && this.activeTab === 'faqs') {
      this.faqSearchKeyword = route.query.q;
    }
  },

  computed: {
    activeTab() {
      return this.vars.activeTabText;
    },

    activeSubTab() {
      const route = useRoute();

      if (route.query.type === 'written') {
        return 'written';
      } else if (route.query.type === 'video') {
        return 'video';
      }
      return '';
    },

    subTitle() {
      return this.vars.subTabTitleText;
    },

    subTabs() {
      return [
        {
          title: 'All',
          name: '',
        },
        {
          title: 'Video Guides',
          name: 'video',
        },
        {
          title: 'E-Guides',
          name: 'written',
        },
      ];
    },

    guides() {
      if (this.activeSubTab) {
        return this.groupedVariables.guides.filter((i) => {
          if (i.is_video && this.activeSubTab === 'video') {
            return i;
          } else if (!i.is_video && this.activeSubTab === 'written') {
            return i;
          }
          return false;
        });
      }
      return this.groupedVariables.guides;
    },

    faqs() {
      const route = useRoute();
      if (route.query.q) {
        const keyword = route.query.q as string;

        const searchEngine = new SearchEngine();

        return searchEngine.searchInArray(this.groupedVariables.faqs, ['title', 'description'], keyword);
      }
      return this.groupedVariables.faqs;
    },
  },

  methods: {
    async onSearchFaqs() {
      this.isFaqResultsLoading = true;
      const router = useRouter();

      await sleep(1000);

      await router.replace({
        query: {
          q: this.faqSearchKeyword,
        },
      });
      this.isFaqResultsLoading = false;
    },
  },
});
</script>
