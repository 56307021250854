<template>
  <lazy-theme3-base-not-found
    class="tw-flex tw-items-center tw-justify-center tw-text-center"
    :is-full-screen-error="true"
  >
    <template v-if="variants.v1 === variant" #title>
      <h1 class="tw-text-5xl" style="color: var(--dark-brown)">
        {{ vars.titleText }}
      </h1>
    </template>
    <template v-else-if="variants.v2 === variant" #title>
      <!-- 404 variant -->
      <h2 class="font-gotham-rounded-medium tw-text-8xl lg:tw-text-[168px]">404</h2>
      <h1 class="font-gotham-rounded-medium tw-text-4xl lg:tw-text-5xl">
        {{ vars.titleText }}
      </h1>
    </template>

    <template #description>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center"
        :class="{
          'tw-mt-6 lg:tw-mt-10': variants.v1 === variant,
          'tw-mt-5': variants.v2 === variant,
        }"
      >
        {{ vars.descriptionText }}

        <div class="tw-mt-12 tw-w-52">
          <lazy-common-inputs-nc-button
            :label="vars.buttonLabelText"
            :is-link="true"
            link="/"
          ></lazy-common-inputs-nc-button>
        </div>
      </div>
    </template>
  </lazy-theme3-base-not-found>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'NotFoundPage',

  mixins: [ComponentMixin],

  setup() {
    const event = useRequestEvent();

    // event will be undefined in the browser
    if (event) {
      // Set the status message as well
      setResponseStatus(event, 404, 'Page Not Found');
    }
  },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    variant() {
      return useVariant(this.component);
    },
  },
});
</script>
