<template>
  <div ref="container" class="comparison-container">
    <div
      ref="before"
      class="comparison-image comparison-before"
      :style="{ backgroundImage: `url(${beforeImage})` }"
    ></div>
    <div class="comparison-image comparison-after" :style="{ backgroundImage: `url(${afterImage})` }"></div>
    <div class="slider" :style="{ left: beforeWidth + '%' }" @mousedown="startDragging" @touchstart="startDragging">
      <span class="slider-handle"
        ><svg
          height="20px"
          width="20px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 349.454 349.454"
          xml:space="preserve"
        >
          <path
            style="fill: var(--c-secondary)"
            d="M347.258,169.425l-82.373-82.375c-2.929-2.929-7.678-2.929-10.606-0.001l-14.143,14.142
      c-1.407,1.407-2.197,3.314-2.197,5.304c0,1.989,0.79,3.896,2.196,5.303l45.429,45.43H63.892l45.429-45.428
      c1.406-1.406,2.196-3.314,2.196-5.303c0-1.989-0.79-3.897-2.196-5.303L95.178,87.05c-2.929-2.928-7.677-2.93-10.607,0L2.196,169.424
      C0.79,170.83,0,172.738,0,174.727s0.79,3.897,2.196,5.303l82.376,82.375c1.465,1.464,3.385,2.197,5.304,2.197
      c1.919,0,3.839-0.732,5.304-2.197l14.143-14.143c1.406-1.406,2.196-3.314,2.196-5.303c0-1.989-0.79-3.897-2.196-5.303l-45.43-45.429
      h221.672l-45.43,45.429c-1.406,1.406-2.196,3.314-2.196,5.303c0,1.989,0.79,3.897,2.196,5.303l14.143,14.143
      c1.407,1.407,3.314,2.197,5.304,2.197c1.989,0,3.897-0.79,5.304-2.197l82.373-82.374
      C350.186,177.102,350.186,172.353,347.258,169.425z"
          /></svg
      ></span>
    </div>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'LibraryImageComparison',
  props: {
    beforeImage: {
      type: String,
      required: true,
    },
    afterImage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      beforeWidth: 50, // Start with half of the width
      isDragging: false,
    };
  },
  methods: {
    startDragging(event) {
      this.isDragging = true;
      window.addEventListener('mousemove', this.onDrag);
      window.addEventListener('mouseup', this.stopDragging);
      window.addEventListener('touchmove', this.onDrag);
      window.addEventListener('touchend', this.stopDragging);
    },

    onDrag(event) {
      if (!this.isDragging) return;

      // Get the X position based on mouse or touch event
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;

      requestAnimationFrame(() => {
        const containerRect = this.$refs.container.getBoundingClientRect();
        const offsetX = clientX - containerRect.left;
        const widthPercentage = Math.min(100, Math.max(0, (offsetX / containerRect.width) * 100));
        this.beforeWidth = widthPercentage;
        this.$refs.before.style.clipPath = `inset(0 ${100 - widthPercentage}% 0 0)`;
      });
    },

    stopDragging() {
      this.isDragging = false;
      window.removeEventListener('mousemove', this.onDrag);
      window.removeEventListener('mouseup', this.stopDragging);
      window.removeEventListener('touchmove', this.onDrag);
      window.removeEventListener('touchend', this.stopDragging);
    },
  },
});
</script>

<style scoped>
.comparison-container {
  position: relative;
  width: 100%;
  user-select: none;
  padding-top: 66.67%; /* Maintain 3:2 aspect ratio */
}

.comparison-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.comparison-before {
  z-index: 2;
  clip-path: inset(0 50% 0 0); /* Start by showing 50% */
}

.comparison-after {
  z-index: 1;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: var(--c-primary);
  cursor: ew-resize;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px 10px 75px 3px var(--c-secondary);
}

.slider-handle {
  position: absolute;
  border-radius: 50%;
  background-color: var(--c-primary);
  padding: 5px;
}

@media (min-width: 1280px) {
  .slider {
    width: 8px;
  }

  .slider-handle {
    padding: 10px;
  }
}
</style>
