<template>
  <div class="pb-100 mt-100">
    <div class="container">
      <lazy-library-title tag="h2" :text="vars.titleText" class="tw-pb-7"></lazy-library-title>
      <lazy-library-text tag="p" :text="vars.descriptionText"></lazy-library-text>
      <div class="row">
        <div v-for="(achievement, index) in achievements" :key="`achievement-${index}`" class="col-sm-4">
          <div class="pxp-numbers-item mt-4 mt-md-5">
            <div class="pxp-numbers-item-number" style="color: var(--camel)">
              <counter-animation
                :component="component"
                :start="0"
                :end="achievement.value"
                :duration="2000"
                :symbol="achievement.symbol"
              ></counter-animation>
            </div>
            <div class="pxp-numbers-item-title">{{ achievement.title }}</div>
            <div class="pxp-numbers-item-text pxp-text-light">
              {{ achievement.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CounterAnimation from '~/components/common/CounterAnimation.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Achievements',

  mixins: [ComponentMixin],

  components: { CounterAnimation },

  computed: {
    achievements() {
      return this.groupedVariables.achievements;
    },
  },
});
</script>
<style scoped></style>
