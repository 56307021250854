<template>
  <div
    class="-tw-mb-12 tw-flex tw-h-[35vh] tw-items-center tw-justify-center"
    :style="`background-color: var(--${vars.backgroundColor});
            background-image: url(${vars.backgroundImage});
          background-position: 50% 60%;`"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6">
          <div class="text-center">
            <h2
              class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl"
              style="text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2)"
              :style="`color: var(--${vars.titleColor})`"
            >
              {{ vars.titleText }}
            </h2>
            <p
              class="font-arial mt-3 mt-lg-4"
              style="text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1)"
              :style="`color: var(--${vars.descriptionColor})`"
            >
              {{ vars.descriptionText }}
            </p>

            <nuxt-link
              :to="vars.buttonUrl"
              :external="true"
              class="pxp-primary-cta text-uppercase mt-3 mt-md-5 pxp-animate"
              :style="`color: var(--${vars.buttonColor}); border-top-color: var(--${vars.buttonColor});`"
              >{{ vars.buttonText }}</nuxt-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'SectionInfo',

  mixins: [ComponentMixin],
});
</script>
