<template>
  <div
    class="pxp-cover pt-100 mb-200 tw-max-h-[418px]"
    :style="`background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${vars.backgroundImage});
        background-position: 50% 60%; `"
  >
    <lazy-library-title tag="h2" :text="vars.titleText" class="tw-text-center tw-text-white"></lazy-library-title>
    <div class="container-fluid pxp-props-carousel-right tw-pb-4">
      <div class="tw-mt-16">
        <div class="pxp-areas-carousel testimonial-slider">
          <div class="pxp-areas-carousel-stage testimonial-carousel-stage owl-carousel">
            <div
              v-for="(testimonial, testimonialIndex) in testimonials"
              :key="`testimonial-${testimonialIndex}`"
              class="pxp-areas-carousel-item tw-mb-4 !tw-flex tw-h-[424px] tw-w-[350px] tw-flex-col tw-gap-8 tw-rounded-md tw-px-6 tw-py-7 tw-shadow-[8px_4px_12px_1px_rgba(0,0,0,0.1)] md:tw-w-[420px] lg:tw-h-[365px]"
              :style="`background-color: var(--${vars.cardBackgroundColor})`"
            >
              <div>
                <span
                  v-for="i in testimonial.star"
                  :key="`star-${i}`"
                  class="fa fa-star tw-mr-1"
                  :style="`color: var(--camel)`"
                ></span>
              </div>
              <div>
                <h3 class="font-arial-bold tw-mb-2">{{ testimonial.title }}</h3>
                <p class="font-arial tw-line-clamp-[7] tw-truncate" style="white-space: unset">
                  {{ testimonial.description }}
                </p>
              </div>
              <h3 class="font-arial-bold tw-mt-auto">
                {{ testimonial.author }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { jQuery } from '~/public/theme3/js/custom.js';

export default defineNuxtComponent({
  name: 'Testimonials',

  mixins: [ComponentMixin],

  computed: {
    testimonials() {
      return this.groupedVariables.testimonials;
    },
  },

  mounted() {
    jQuery.testimonialSlider();
  },
});
</script>

<style scoped>
@media screen and (min-width: 1400px) {
  .pxp-props-carousel-right {
    padding-left: calc((100% - 1340px) / 2 + 15px);
  }
}
@media screen and (max-width: 1199px) {
  .pxp-props-carousel-right {
    padding-left: calc((100% - 960px) / 2 + 15px);
  }
}

@media screen and (max-width: 991px) {
  .pxp-props-carousel-right {
    padding-left: calc((100% - 720px) / 2 + 15px);
  }
}

@media screen and (max-width: 767px) {
  .pxp-props-carousel-right {
    padding-left: calc((100% - 540px) / 2 + 15px);
  }
}

@media screen and (max-width: 575px) {
  .pxp-props-carousel-right {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.pxp-areas-carousel {
  padding-left: 0 !important;
}

.pxp-areas-carousel-stage .pxp-areas-carousel-item {
  display: none;
}

.owl-stage .owl-item .pxp-areas-carousel-item {
  display: block;
}

.pxp-areas-carousel {
  padding: 0;
}
</style>
