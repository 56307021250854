<template>
  <div class="pxp-services-tabs mt-100">
    <div class="container">
      <div class="tw-grid tw-grid-cols-1 tw-items-center tw-justify-between tw-gap-7 lg:tw-grid-cols-2 lg:tw-gap-20">
        <div class="tw-grid-cols-6" :class="{ 'md:tw-order-2': vars.isReverseNumber === 1 }">
          <lazy-library-title tag="h2" :text="vars.titleText"></lazy-library-title>

          <lazy-library-text class="tw-mt-8 lg:tw-mt-12" tag="p" :text="vars.descriptionText"></lazy-library-text>
        </div>
        <div class="tw-grid-cols-6" :class="{ 'md:tw-order-1': vars.isReverseNumber === 1 }">
          <div class="pxp-services-tabs-items mt-4 mt-md-5 mt-lg-0">
            <div
              id="pxp-services-tabs-carousel"
              class="carousel slide carousel-fade pxp-services-tabs-carousel"
              data-ride="carousel"
              data-interval="false"
            >
              <div class="carousel-inner">
                <library-image :src="vars.cardImage"> </library-image>
              </div>
            </div>
            <div class="pxp-services-tabs-items-content">
              <div
                id="pxp-services-tabs-content-carousel"
                class="carousel slide carousel-fade"
                data-ride="carousel"
                data-interval="false"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="pxp-services-tabs-content-item">
                      <lazy-library-text class="" tag="p" :text="vars.cardDescriptionText"></lazy-library-text>
                      <nuxt-link
                        :external="true"
                        :to="vars.buttonUrl"
                        class="pxp-search-properties-btn tw-mt-10"
                        :style="`background-color: var(--${vars.buttonBackgroundColor}); color: var(--${vars.buttonTextColor});`"
                        >{{ vars.buttonText }}</nuxt-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurPlan',

  mixins: [ComponentMixin],
});
</script>

<style scoped>
.pxp-services-tabs-items .carousel.pxp-services-tabs-carousel {
  height: 366px;
}
</style>
