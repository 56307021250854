<template>
  <div
    v-if="variants.v1 === variant"
    v-show="!isAnythingOnTopVisible"
    class="pxp-banner tw-fixed tw-z-[999] tw-flex tw-h-12 tw-w-full tw-items-center tw-justify-center tw-px-4 tw-transition-all tw-duration-500 tw-ease-in-out"
    :style="`background-color: var(--${vars.backgroundColor}); top: ${headerHeight}px !important`"
  >
    <p class="tw-text-xs md:tw-text-base" style="color: var(--white)">
      {{ vars.bannerText }}
    </p>
    <nuxt-link
      :external="true"
      :to="vars.bannerButtonUrl"
      class="pxp-search-properties-btn tw-ml-8 lg:tw-block"
      :style="`background-color: var(--${vars.buttonBackgroundColor})`"
    >
      {{ vars.buttonText }}
    </nuxt-link>
  </div>
  <div
    v-else-if="variants.v2 === variant"
    v-show="!isAnythingOnTopVisible"
    class="banner-v-2 pxp-banner tw-fixed tw-z-[999] tw-flex tw-h-12 tw-w-full tw-items-center tw-justify-center tw-pl-4 tw-transition-all tw-duration-500 tw-ease-in-out md:tw-px-4 md:tw-py-7"
    :style="`background-image: url(${vars.backgroundImage}); top: ${headerHeight}px !important`"
  >
    <p class="tw-text-xs md:tw-text-base" style="color: var(--white)">
      {{ vars.bannerText }}
    </p>
    <div class="tw-ml-8">
      <nc-button :link="vars.bannerButtonUrl" :is-link="true" :label="vars.buttonText" :height="45"> </nc-button>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { RepoStates } from '~/units/core/types';
import { mapState } from 'pinia';
import { useCoreStore } from '~/units/core/store';

export default defineNuxtComponent({
  name: 'Banner',
  components: { NcButton },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'BackgroundImageV2',
      },
    };
  },

  computed: {
    ...mapState(useCoreStore, ['headerHeight', 'isAnythingOnTopVisible']),
  },

  mixins: [ComponentMixin],
});
</script>

<style scoped>
.pxp-banner.pxp-is-sticky.banner-v-2 {
  top: 108px !important;
}
</style>
