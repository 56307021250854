<template>
  <div class="row nc-text" :class="isLatestCarouselVisible ? 'tw-mt-20 xl:tw-mt-24' : 'tw-mt-12'">
    <teleport v-if="isLatestCarouselEnabled" to="#special-place">
      <div class="container tw-pt-40 max-[991px]:tw-max-w-full">
        <theme3-blogs-latests-blogs :component="component" />
      </div>
    </teleport>

    <lazy-library-title v-if="titleHtml" :html="titleHtml" tag="h2" class="tw-mb-10"></lazy-library-title>

    <div v-if="isSearchBoxEnabled" class="col-sm-12 tw-mb-10">
      <search-box
        v-model="keyword"
        :search-result-list="[]"
        :placeholder="searchBoxPlaceholder"
        :search-method="onSearched"
      />
    </div>

    <div v-if="isBlogsLoading" style="height: 60dvh">
      <loader :loader-color="colors.brown" />
    </div>
    <div v-else-if="blogs && blogs.length === 0">
      <lazy-library-not-found :title="notFound.title" :description="notFound.description" />
    </div>
    <div v-else-if="!isBlogsLoading" class="tw-grid tw-grid-cols-12 tw-gap-x-3 tw-gap-y-3">
      <div
        v-for="(blog, index) in blogs"
        :key="`blog-${index}`"
        class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-12 xl:tw-col-span-6"
      >
        <lazy-theme3-blogs-blog-card v-if="vars.blogCardTypeText !== 'wide-card'" :blog="blog" :index="index" />
        <theme3-blogs-blog-item-wide-card v-if="vars.blogCardTypeText === 'wide-card'" :blog="blog" :index="index" />
      </div>
    </div>
    <div v-if="isPaginationVisible" class="col-sm-12">
      <lazy-library-blogs-pagination :component="component" />
    </div>
  </div>
</template>

<script lang="ts">
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme2/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import SearchBox from '~/components/common/SearchBox.vue';

export default defineNuxtComponent({
  name: 'Theme3BlogsPage',
  components: { SearchBox, NcButton, NotFound, Loader },

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  data() {
    return {
      variants: {
        v1: 'v1',
      },
      keyword: '',
      searchBoxPlaceholder: 'Search',
    };
  },

  mounted() {
    const route = useRoute();
    // TODO: Check where we are in blogs list or not
    if (route.query.q) {
      this.keyword = route.query.q as string;
    }
  },

  computed: {
    isSearchBoxEnabled() {
      return this.vars.isSearchBoxEnabledNumber;
    },

    isLatestCarouselEnabled() {
      return this.vars.isLatestCarouselEnabledNumber;
    },

    isLatestCarouselVisible() {
      const route = useRoute();
      return this.isLatestCarouselEnabled && this.blogs.length !== 0 && !route.query.q && !route.query.page;
    },

    isPaginationEnabled() {
      return this.vars.isPaginationEnabledNumber;
    },

    isPaginationVisible() {
      return this.isPaginationEnabled && !this.isBlogsLoading;
    },

    variant() {
      return useVariant(this.component);
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    titleHtml() {
      return this.vars.titleHtml;
    },
  },

  methods: {
    async onSearched() {
      const blogsStore = useBlogsStore();
      await blogsStore.searchBlogs(this.keyword);
    },
  },

  watch: {
    async $route(a, b) {
      if (a.query.q !== b.query.q) {
        await fetchBlogsManager(this.component, a.query && a.query.q ? a.query.q : null);
      }
    },
  },
});
</script>
