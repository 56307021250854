<template>
  <div>
    <span class="font-plus-jakarta-bold">{{ current }}</span>
    <span class="font-plus-jakarta-bold">{{ symbol }}</span>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CounterAnimation',

  mixins: [ComponentMixin],

  props: {
    start: {
      type: Number,
      required: true,
    },
    end: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    symbol: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      current: this.start,
    };
  },

  methods: {
    animateValue() {
      const range = this.end - this.start;
      const increment = range / (this.duration / 16);
      const startTime = performance.now();

      const updateCounter = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        if (elapsedTime < this.duration) {
          this.current = Math.floor(this.start + increment * (elapsedTime / 16));
          requestAnimationFrame(updateCounter);
        } else {
          this.current = this.end;
        }
      };

      requestAnimationFrame(updateCounter);
    },
  },
  mounted() {
    this.animateValue();
  },
});
</script>
