<template>
  <div v-if="variants.v1 === variant" id="estas-widget-bcfb96" class="estas-widget-default"></div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'EstasWidgets',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'browns',
      },
    };
  },

  mounted() {
    if (this.variants.v1 === this.variant) {
      (function (w, d, s, o, f, js, fjs) {
        w['ESTAS-Widget'] = o;
        w[o] =
          w[o] ||
          function () {
            (w[o].q = w[o].q || []).push(arguments);
          };
        (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(window, document, 'script', 'estasbcfb96', 'https://www.theestas.com/assets/js/min/widget.bundle.js');
      estasbcfb96('init', {
        el: 'estas-widget-bcfb96',
        key: 'On2SNxkA1HT7uW76RXE8RNJGEHhCpqPNPVhHhcnwyLtKLHus98e5e7WtGzJM',
        showComments: true,
        showPhil: true,
        floatingWidget: false,
        minimumRating: 1,
        name: 'name',
      });
      (window.estasWidget = {
        open: function () {
          document.querySelector('.estas-widget-default').style.display = 'block';
        },
        close: function () {
          document.querySelector('.estas-widget-default').style.display = 'none';
        },
      }),
        (window.onmessage = function (a) {
          'closeEstasWidget' == a.data && window.estasWidget.close();
        });
    }
  },
});
</script>
