<template>
  <div style="position: relative">
    <div class="pxp-cta-3 tw-py-24" :style="`background: var(--${vars.backgroundColor});`">
      <div class="container">
        <div
          class="tw-flex tw-w-full tw-flex-col-reverse tw-items-center tw-justify-center tw-gap-y-7 lg:tw-max-w-none lg:tw-gap-x-32 xl:tw-gap-x-40"
          :class="{ 'lg:tw-flex-row-reverse': true, 'lg:tw-flex-row': !true }"
        >
          <div
            v-if="descriptions.length > 0"
            class="tw-flex tw-flex-col tw-items-start tw-gap-4 lg:tw-basis-[70%] lg:tw-gap-6 xl:tw-basis-[90%]"
          >
            <h2 class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl">
              {{ vars.titleText }}
            </h2>
            <div class="tw-flex tw-flex-col tw-gap-y-4">
              <p v-for="(item, index) in descriptions" :key="`infobox-descriptions-${index}`">
                {{ item.description }}
              </p>
            </div>
            <nuxt-link
              v-if="vars.ctaUrl && vars.ctaText"
              :to="vars.ctaUrl"
              class="pxp-primary-cta text-uppercase pxp-animate md:tw-mt-7 lg:tw-mt-6"
              :external="true"
            >
              {{ vars.ctaText }}
            </nuxt-link>
          </div>
          <div class="tw-w-full tw-max-w-[1000px]">
            <h2
              v-if="descriptions.length === 0 && vars.titleText"
              class="font-plus-jakarta-semibold tw-mb-6 tw-text-center tw-text-3xl lg:tw-text-4xl"
            >
              {{ vars.titleText }}
            </h2>
            <LibraryImageComparison
              v-if="vars.beforeImage"
              :before-image="vars.beforeImage"
              :after-image="vars.afterImage"
              style="border-radius: var(--rounded)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'ComparisonBox',
  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
      },
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
