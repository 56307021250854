<template>
  <library-container>
    <div class="tw-grid tw-grid-cols-12 tw-gap-y-12 lg:tw-gap-x-12 xl:tw-gap-x-24">
      <div class="tw-col-span-12 lg:tw-col-span-6 lg:tw-w-auto" :class="isReverse ? 'tw-order-2' : 'tw-order-1'">
        <library-title :text="vars.mapTitleText" tag="h3" class="tw-mb-10"></library-title>
        <neuron-google-map-v2
          v-if="locations && locations.length"
          v-model:markers="locations"
          :marker-color="markerColor"
          :variant="variant"
          :center="locations[0]"
        ></neuron-google-map-v2>
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-6" :class="isReverse ? 'tw-order-1' : 'tw-order-2'">
        <library-form v-if="form" :form="form" :component="component"></library-form>
      </div>
    </div>
  </library-container>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import NeuronGoogleMapV2 from '~/components/common/NeuronGoogleMapV2.vue';

export default defineNuxtComponent({
  name: 'Theme3BranchMapAndContactForm',
  components: { NeuronGoogleMapV2 },

  mixins: [ComponentMixin],

  data() {
    return {
      locations: [],
      form: null,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  created() {
    const branches = this.groupedVariables.branches;

    this.locations = branches.map((branch) => {
      if (this.variants.v1 === this.variant) {
        return {
          lat: branch.lat,
          lng: branch.long,
          color: this.colors[branch.marker],
        };
      } else if (this.variant === this.variants.v2) {
        return {
          lat: branch.lat,
          lng: branch.long,
          name: branch.name,
          phone: branch.phone,
          button: branch.button,
          buttonLabel: branch.button_label,
          buttonType: branch.button_type,
          buttonTextColor: branch.button_text,
          buttonBackgroundColor: branch.button_background,
        };
      }
    });

    this.form = this.parseFormV2(this.vars.contactForm);
  },

  computed: {
    markerColor() {
      return this.colors.purple;
    },

    isReverse() {
      return this.vars.isReverseBool;
    },

    variant() {
      return useVariant(this.component);
    },
  },
});
</script>
