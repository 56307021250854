<template>
  <div
    id="our-team"
    class="container mt-100"
    :style="`--nc-card-hover-color: var(--${cardBackgroundColors.hover}); --nc-stars-card-bg-color: var(--${starsCardBackgroundColors.normal}); --nc-card-hover-text-color: var(--${cardTextColors.hover})`"
  >
    <lazy-library-title tag="h3" :text="vars.titleText" :class="`text-${titlePosition}`"></lazy-library-title>

    <library-text v-if="vars.descriptionText" class="tw-mt-3" style="color: #666666" :text="vars.descriptionText">
    </library-text>

    <div class="row mt-4 mt-md-5 tw-justify-center">
      <div
        v-for="(member, index) in members"
        :key="`member-${index}`"
        class="col-sm-12 col-md-6 col-xl-4 tw-mb-3 tw-cursor-pointer"
      >
        <div
          class="pxp-agents-1-item"
          style="height: 100% !important"
          @click="toggleTeamMemberDetailPopup(member, true)"
        >
          <div class="rounded-lg">
            <library-image :src="member.photo" :w-ratio="7" :h-ratio="8">
              <template #overlay-item>
                <div
                  class="pxp-agents-1-item-details rounded-lg"
                  :style="`background-color: var(--${cardBackgroundColors.normal}); color: var(--${cardTextColors.normal});`"
                >
                  <lazy-library-text
                    type="bold"
                    :text="`${member.name} ${member.surname}`"
                    class="tw-mb-4"
                  ></lazy-library-text>
                  <div v-if="member.phone" class="pxp-agents-1-item-details-email">
                    <span class="fa fa-phone tw-pr-1"></span>
                    <lazy-library-text
                      tag="span"
                      class="tw-break-all md:tw-text-sm lg:tw-text-base"
                      :text="member.phone"
                    ></lazy-library-text>
                  </div>
                  <div
                    v-if="member.email"
                    class="pxp-agents-1-item-details-email my-1 tw-flex tw-items-center tw-justify-center tw-text-wrap"
                  >
                    <span class="fa fa-envelope tw-pr-1"></span>
                    <lazy-library-text
                      tag="p"
                      :text="member.email"
                      class="tw-break-all md:tw-text-sm lg:tw-text-base"
                    ></lazy-library-text>
                  </div>
                  <div class="pxp-agents-1-item-details-spacer"></div>

                  <div class="pxp-agents-1-item-cta text-uppercase" :style="`color: var(--${cardTextColors.normal})`">
                    MORE DETAILS
                  </div>
                </div>
                <div class="pxp-agents-1-item-rating">
                  <span>
                    <span
                      v-for="i in 5"
                      :key="i"
                      class="fa fa-star"
                      :style="`color: var(--${starsCardTextColors.normal})`"
                    ></span>
                  </span>
                </div>
              </template>
            </library-image>
          </div>
        </div>
      </div>
    </div>

    <nuxt-link
      v-if="vars.ctaUrl && vars.ctaText"
      :to="vars.ctaUrl"
      :external="true"
      :title="vars.ctaText"
      class="pxp-primary-cta text-uppercase mt-md-4 animate tw-flex tw-items-center tw-pt-12"
      :class="
        titlePosition === 'center'
          ? 'tw-justify-center'
          : titlePosition === 'left'
            ? 'tw-justify-start'
            : 'tw-justify-end'
      "
      >{{ vars.ctaText || 'See All Agents' }}</nuxt-link
    >

    <popup v-if="selectedMember && popupConfig.isActive" :config="popupConfig" custom-width="80%">
      <template #content>
        <div class="tw-grid tw-h-full tw-w-full tw-grid-cols-12 tw-gap-y-4 tw-py-4 lg:tw-gap-x-4">
          <div class="tw-col-span-12 md:tw-col-span-8 lg:tw-col-span-5">
            <div>
              <div style="position: relative; height: 100%; width: 100%">
                <library-image :src="selectedMember.photo" :w-ratio="0.814" :h-ratio="1">
                  <template v-if="selectedMember.detail_video" #overlay-item>
                    <div class="pxp-video-section-trigger" style="cursor: pointer" @click="toggleVideoPopup()">
                      <span class="fa fa-play"></span>
                    </div>
                  </template>
                </library-image>
              </div>
            </div>
          </div>
          <div class="tw-col-span-12 tw-flex tw-w-full tw-flex-col tw-justify-center lg:tw-col-span-7">
            <library-title
              :text="`${selectedMember.name} ${selectedMember.surname}`"
              tag="h3"
              class="tw-mb-5"
            ></library-title>
            <div
              v-if="selectedMember.email"
              class="pxp-agents-1-item-details-email my-1 tw-mb-3 tw-flex tw-items-center tw-text-wrap"
            >
              <span class="fa fa-envelope tw-pr-1"></span>
              <lazy-library-text tag="p" :text="selectedMember.email" class="tw-break-all"></lazy-library-text>
            </div>
            <div v-if="selectedMember.phone" class="pxp-agents-1-item-details-email tw-mb-3">
              <span class="fa fa-phone tw-pr-1"></span>
              <lazy-library-text tag="span" :text="selectedMember.phone"></lazy-library-text>
            </div>
            <div class="text-sm tw-mb-3" v-html="selectedMember.detail_description"></div>
            <div class="md:tw-w-2/6 lg:tw-w-1/6">
              <nc-button
                :label="selectedMember.button_label"
                :is-link="true"
                :link="`mailto:${selectedMember.email}`"
              ></nc-button>
            </div>
          </div>
        </div>
      </template>
    </popup>

    <library-popups-video-popup
      v-if="selectedMember && isVideoPopupOpen"
      v-model:is-visible="isVideoPopupOpen"
      :video-url="selectedMember.detail_video"
    />
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import Popup from '~/components/common/popup/Popup.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';

export default defineNuxtComponent({
  name: 'OurTeam',
  components: { NcButton, Popup },

  mixins: [ComponentMixin],

  data() {
    return {
      selectedMember: null,
      popupConfig: {
        isActive: false,
      },

      isVideoPopupOpen: false,
    };
  },

  computed: {
    members() {
      return this.groupedVariables.members;
    },

    cardBackgroundColors() {
      return {
        normal: this.vars.cardBackgroundColor,
        hover: this.vars.cardBackgroundHoverColor,
      };
    },

    cardTextColors() {
      return {
        normal: this.vars.cardTextColor,
        hover: this.vars.cardTextHoverColor,
      };
    },

    starsCardBackgroundColors() {
      return {
        normal: this.vars.starsCardBackgroundColor,
        hover: this.vars.starsCardBackgroundHoverColor,
      };
    },

    starsCardTextColors() {
      return {
        normal: this.vars.starsCardTextColor,
        hover: this.vars.starsCardTextHoverColor,
      };
    },

    titlePosition() {
      return this.vars.titlePositionText || 'center';
    },
  },

  methods: {
    toggleTeamMemberDetailPopup(member = null, value = false) {
      this.selectedMember = member;
      this.popupConfig.isActive = value;
    },

    toggleVideoPopup() {
      this.isVideoPopupOpen = !this.isVideoPopupOpen;
    },
  },
});
</script>

<style scoped>
.pxp-agents-1-item-details-email {
  color: unset;
  opacity: unset;
}

a:hover .pxp-agents-1-item-details {
  background-color: var(--nc-card-hover-color) !important;
}

a:hover .pxp-agents-1-item-details > * {
  color: var(--white);
}

@media screen and (max-width: 768px) {
  .pxp-agents-1-item {
    height: 400px;
  }
}

.pxp-agents-1-item-rating:before {
  background-color: var(--nc-stars-card-bg-color);
}

a:hover .pxp-agents-1-item-cta {
  color: var(--nc-card-hover-text-color) !important;
}
</style>
