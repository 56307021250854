<template>
  <div class="container tw-pt-8 lg:tw-pt-16">
    <h2 class="font-plus-jakarta-semibold tw-text-center tw-text-3xl lg:tw-text-4xl">
      {{ vars.titleText }}
    </h2>
    <div class="tw-overflow-x-auto tw-pt-12 lg:tw-pt-20">
      <table class="tw-min-w-full tw-drop-shadow-xl" style="background-color: var(--isabelline)">
        <thead>
          <tr>
            <th
              class="font-arial-bold tw-bg-gray-200 tw-px-10 tw-py-2 lg:tw-px-12"
              style="background-color: rgba(191, 168, 108, 0.1)"
            >
              {{ vars.column1TitleText }}
            </th>
            <th
              class="font-arial-bold tw-bg-gray-200 tw-px-4 tw-py-2 tw-text-left"
              style="background-color: rgba(191, 168, 108, 0.1)"
            >
              {{ vars.column2TitleText }}<br /><span class="font-arial-bold tw-text-2xl" style="color: var(--camel)">{{
                vars.column2DescriptionText
              }}</span>
            </th>
            <th
              class="font-arial-bold tw-bg-gray-200 tw-px-4 tw-py-2 tw-text-left"
              style="background-color: rgba(191, 168, 108, 0.1)"
            >
              {{ vars.column3TitleText }}<br /><span class="font-arial-bold tw-text-2xl" style="color: var(--camel)">{{
                vars.column3DescriptionText
              }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in features" :key="`compare-${index}`" class="tw-px-10">
            <td class="font-arial tw-px-4 tw-py-6 lg:tw-px-12">
              {{ item.feature }}
              <span class="font-arial-bold tw-text-[var(--purple)]">{{ item.feature_description }}</span>
            </td>

            <td class="tw-px-4 tw-py-6">
              <svg
                v-if="item.col1_state === 1"
                class=""
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="#BFA86C"
                />
              </svg>
              <svg v-else width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M27.5775 25.5581C27.8457 25.8263 27.9964 26.1902 27.9964 26.5696C27.9964 26.9489 27.8457 27.3128 27.5775 27.581C27.3092 27.8493 26.9454 28 26.566 28C26.1866 28 25.8228 27.8493 25.5545 27.581L17.9994 20.0235L10.4419 27.5787C10.1737 27.8469 9.80982 27.9976 9.43044 27.9976C9.05106 27.9976 8.68723 27.8469 8.41897 27.5787C8.15071 27.3104 8 26.9466 8 26.5672C8 26.1878 8.15071 25.824 8.41897 25.5557L15.9765 18.0006L8.42135 10.4431C8.15309 10.1748 8.00238 9.81101 8.00238 9.43163C8.00238 9.05225 8.15309 8.68842 8.42135 8.42016C8.68961 8.1519 9.05344 8.00119 9.43282 8.00119C9.8122 8.00119 10.176 8.1519 10.4443 8.42016L17.9994 15.9776L25.5569 8.41897C25.8252 8.15071 26.189 8 26.5684 8C26.9477 8 27.3116 8.15071 27.5798 8.41897C27.8481 8.68723 27.9988 9.05106 27.9988 9.43044C27.9988 9.80982 27.8481 10.1737 27.5798 10.4419L20.0224 18.0006L27.5775 25.5581Z"
                  fill="#999999"
                />
              </svg>
            </td>

            <td class="tw-px-4 tw-py-6">
              <svg
                v-if="item.col2_state === 1"
                class=""
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="#BFA86C"
                />
              </svg>
              <svg v-else width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M27.5775 25.5581C27.8457 25.8263 27.9964 26.1902 27.9964 26.5696C27.9964 26.9489 27.8457 27.3128 27.5775 27.581C27.3092 27.8493 26.9454 28 26.566 28C26.1866 28 25.8228 27.8493 25.5545 27.581L17.9994 20.0235L10.4419 27.5787C10.1737 27.8469 9.80982 27.9976 9.43044 27.9976C9.05106 27.9976 8.68723 27.8469 8.41897 27.5787C8.15071 27.3104 8 26.9466 8 26.5672C8 26.1878 8.15071 25.824 8.41897 25.5557L15.9765 18.0006L8.42135 10.4431C8.15309 10.1748 8.00238 9.81101 8.00238 9.43163C8.00238 9.05225 8.15309 8.68842 8.42135 8.42016C8.68961 8.1519 9.05344 8.00119 9.43282 8.00119C9.8122 8.00119 10.176 8.1519 10.4443 8.42016L17.9994 15.9776L25.5569 8.41897C25.8252 8.15071 26.189 8 26.5684 8C26.9477 8 27.3116 8.15071 27.5798 8.41897C27.8481 8.68723 27.9988 9.05106 27.9988 9.43044C27.9988 9.80982 27.8481 10.1737 27.5798 10.4419L20.0224 18.0006L27.5775 25.5581Z"
                  fill="#999999"
                />
              </svg>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tw-flex tw-items-center tw-justify-center tw-pt-12">
      <nuxt-link
        :to="vars.buttonHrefUrl"
        :external="true"
        :class="{
          'pxp-search-properties-btn': vars.buttonTypeText === 'primary',
          'pxp-primary-cta pxp-animate after:tw-border-inherit': vars.buttonTypeText === 'secondary',
        }"
        :style="`background-color: var(--${vars.buttonBackgroundColor}); color: var(--${vars.buttonTextColor}); ${
          vars.buttonTypeText === 'secondary' ? `border-top-color: var(--${vars.buttonTextColor});` : ''
        }`"
      >
        {{ vars.buttonText }}
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CompareTable',

  mixins: [ComponentMixin],

  computed: {
    features() {
      return this.groupedVariables.features;
    },
  },
});
</script>

<style scoped>
tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--black);
}
tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--black);
}
</style>
